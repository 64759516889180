import { Routes } from '@angular/router';
const ɵ0 = () => import("./index/index.module.ngfactory").then(m => m.IndexPageModuleNgFactory);
const routes = [
    /*  {
      path: '',
      loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
    },*/
    {
        path: '',
        loadChildren: ɵ0
    },
    { path: 'dealer', loadChildren: './dealer/link/link.module#LinkPageModule' },
    { path: 'index', loadChildren: './index/index.module#IndexPageModule' },
    { path: 'ingreso', loadChildren: './formulario-ingreso/formulario-ingreso.module#FormularioIngresoPageModule' },
    { path: 'elegir-inspeccion', loadChildren: './elegir-inspeccion/elegir-inspeccion.module#ElegirInspeccionPageModule' },
    { path: 'informacion-general-auto', loadChildren: './informacion-general-auto/informacion-general-auto.module#InformacionGeneralAutoPageModule' },
    { path: 'paso-dos-inspeccion-home', loadChildren: './paso-dos-inspeccion-home/paso-dos-inspeccion-home.module#PasoDosInspeccionHomePageModule' },
    { path: 'tomar-foto', loadChildren: './tomar-foto/tomar-foto.module#TomarFotoPageModule' },
    { path: 'tabs-pantalla', loadChildren: './tabs-pantalla/tabs-pantalla.module#TabsPantallaPageModule' },
    { path: 'tabs1-pantalla', loadChildren: './tabs1-pantalla/tabs1-pantalla.module#Tabs1PantallaPageModule' },
    { path: 'tabs3-pantalla', loadChildren: './tabs3-pantalla/tabs3-pantalla.module#Tabs3PantallaPageModule' },
    //{ path: 'ayuda', loadChildren: './ayuda/ayuda.module#AyudaPageModule' },
    { path: 'chasis-camion', loadChildren: './chasis-camion/chasis-camion.module#ChasisCamionPageModule' },
    { path: 'chasis', loadChildren: './chasis-vehiculo/chasis-vehiculo.module#ChasisVehiculoPageModule' },
    { path: 'chasis-vehiculo', loadChildren: './chasis-vehiculo/chasis-vehiculo.module#ChasisVehiculoPageModule' },
    { path: 'chasis-motocicleta', loadChildren: './chasis-motocicleta/chasis-motocicleta.module#ChasisMotocicletaPageModule' },
    { path: 'accesorios', loadChildren: './accesorios/accesorios.module#AccesoriosPageModule' },
    { path: 'tomar-foto-accesorio', loadChildren: './tomar-foto-accesorio/tomar-foto-accesorio.module#TomarFotoAccesorioPageModule' },
    { path: 'piezas-danos', loadChildren: './piezas-danos/piezas-danos.module#PiezasDanosPageModule' },
    { path: 'fin-revision', loadChildren: './fin-revision/fin-revision.module#FinRevisionPageModule' },
    { path: 'image-error', loadChildren: './image-error/image-error.module#ImageErrorPageModule' },
    { path: 'deducibles', loadChildren: './dedusibles-uf/dedusibles-uf.module#DedusiblesUfPageModule' },
    { path: 'fin-errorimagenes', loadChildren: './fin-errorimagenes/fin-errorimagenes.module#FinErrorimagenesPageModule' },
    { path: 'browser', loadChildren: './iphone-safari/iphone-safari.module#IphoneSafariPageModule' },
    { path: 'error', loadChildren: './enlase-nodisponible/enlase-nodisponible.module#EnlaseNodisponiblePageModule' },
    { path: 'culmino-tiempo', loadChildren: './culmino-tiempo/culmino-tiempo.module#CulminoTiempoPageModule' },
    { path: 'reactivar', loadChildren: './reactivar-ins/reactivar-ins.module#ReactivarInsPageModule' },
    { path: 'pantalla-finalizar', loadChildren: './pantalla-finalizar/pantalla-finalizar.module#PantallaFinalizarPageModule' },
    { path: 'deducibles-aceptada', loadChildren: './deducibles-aceptada/deducibles-aceptada.module#DeduciblesAceptadaPageModule' },
    { path: 'deducibles-rechazada', loadChildren: './deducibles-rechazada/deducibles-rechazada.module#DeduciblesRechazadaPageModule' },
    { path: 'grabar-video', loadChildren: './grabar-video/grabar-video.module#GrabarVideoPageModule' },
    { path: 'doc-complementario', loadChildren: './doc-complementario/doc-complementario.module#DocComplementarioPageModule' },
    // { path: 'rueda-encontrar', loadChildren: './rueda-encontrar/rueda-encontrar.module#RuedaEncontrarPageModule' },
    // la siguiente ruta debe estar al final 
    { path: '**', loadChildren: './enlase-nodisponible/enlase-nodisponible.module#EnlaseNodisponiblePageModule' },
];
export class AppRoutingModule {
}
export { ɵ0 };
